import Link from "next/link"
import { Routes } from "@blitzjs/next"
import { Button } from "@mui/material"

export const LoginButton = () => {
  return (
    <Link href={Routes.LoginPage()} passHref legacyBehavior>
      {/*<Link href={Routes.ShopLoginPage({ school: "blik" })} passHref legacyBehavior>*/}
      <Button color="secondary" size="large" data-hook={"loginbtn"}>
        Inloggen
      </Button>
    </Link>
  )
}
