import { useEffect } from "react"

export const useHideLoginBtn = () => {
  useEffect(() => {
    const loginButton: HTMLElement | null = document.querySelector('[data-hook="loginbtn"]')
    if (loginButton) {
      loginButton.style.display = "none"
    }
    return () => {
      if (loginButton) {
        loginButton.style.display = "block"
      }
    }
  }, [])
}
